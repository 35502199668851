import * as React from "react"
import { SVGProps } from "react"

const EyeClose = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M11.9999 7.59817C15.1008 7.59817 17.8663 9.30217 19.2163 11.9982C18.7335 12.9742 18.0545 13.8142 17.2445 14.4942L18.3981 15.6222C19.5354 14.6382 20.4354 13.4062 20.9999 11.9982C19.5845 8.48617 16.0908 5.99817 11.9999 5.99817C10.9608 5.99817 9.96264 6.15817 9.02173 6.45417L10.3717 7.77417C10.9035 7.67017 11.4435 7.59817 11.9999 7.59817Z"
			fill="#7D828C"
		/>
		<path
			d="M11.1245 8.51007L12.8181 10.1661C13.2845 10.3661 13.6609 10.7341 13.8654 11.1901L15.5591 12.8461C15.6245 12.5741 15.6736 12.2861 15.6736 11.9901C15.6818 10.0061 14.0291 8.39807 12 8.39807C11.6972 8.39807 11.4109 8.43807 11.1245 8.51007Z"
			fill="#7D828C"
		/>
		<path
			d="M3.82636 5.8938L6.01909 8.0378C4.68545 9.0618 3.63 10.4218 3 11.9978C4.41545 15.5098 7.90909 17.9978 12 17.9978C13.2436 17.9978 14.4382 17.7658 15.5345 17.3418L18.3327 20.0778L19.4864 18.9498L4.98 4.7578L3.82636 5.8938ZM9.96273 11.8938L12.0982 13.9818C12.0655 13.9898 12.0327 13.9978 12 13.9978C10.8709 13.9978 9.95455 13.1018 9.95455 11.9978C9.95455 11.9578 9.96273 11.9338 9.96273 11.8938ZM7.18091 9.1738L8.61273 10.5738C8.42455 11.0138 8.31818 11.4938 8.31818 11.9978C8.31818 13.9818 9.97091 15.5978 12 15.5978C12.5155 15.5978 13.0064 15.4938 13.4482 15.3098L14.25 16.0938C13.53 16.2858 12.7773 16.3978 12 16.3978C8.89909 16.3978 6.13364 14.6938 4.78364 11.9978C5.35636 10.8538 6.19091 9.9098 7.18091 9.1738Z"
			fill="#7D828C"
		/>
	</svg>
)

export default EyeClose
