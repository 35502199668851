import commonData from "../../pages-data/common/commonData"

export default {
	title: "Не удаётся войти?",
	description:
		"Укажите почту, которую использовали при регистрации, и мы отправим вам ссылку на восстановление пароля. Не помните email? Позвоните по номеру <a href='tel:88005002138'>8 800 500-21-38</a> (бесплатно)",
	emailField: {
		name: "email",
		label: "Email",
		type: "email",
		placeholder: "example@email.com",
	},
	submitButtonText: "Восстановить пароль",
	backLinkText: "Вернуться назад",
	errorData: {
		header: "Ошибка запроса",
		description:
			"Запрос на восстановление пароля прерван из-за проблем на сервере. Пожалуйста, попробуйте позже или обратитесь к нам для завершения процесса.",
		backButtonText: commonData.serverErrorScreen.buttonText,
		phone: commonData.phone,
		email: commonData.emailSupport,
		chatraGreetingsText:
			"Привет! Вижу, что произошла ошибка. Если исправили самостоятельно — отлично! Если нужна помощь — напишите мне, буду рад помочь!",
	},
}
