import {Locales} from "../../../localization/types";
import { PageLinkItem, pagesLinks } from "../../../pages-data/_V2/common/links";

export const getData = (locale: Locales) => {
  if (locale !== 'ru-RU') {
    return {
      licenceAgreement: pagesLinks.lisenceAgreementNotRu as PageLinkItem,
      privacyPolicy: pagesLinks.privacyPolicyNotRu as PageLinkItem,
    }
  } else {
    return {
      licenceAgreement: pagesLinks.lisenceAgreement as PageLinkItem,
      privacyPolicy: pagesLinks.privacyPolicy as PageLinkItem,
    }
  }
}
