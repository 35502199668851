import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "@reach/router"

import classNames from "classnames"

import ValidityOkIcon from "../../../assets/_V2/svg/ValidityOkIcon"

import { ACTION_STATUSES } from "../RequestCallModal/RequestCallModal"
import { getEmailValidState } from "../../../utils/validateFunctions"

import styles from "./styles.module.scss"

interface Props {
	inputId: string
	className?: string
	inputTitle?: string
	value: string
	actionStatus: ACTION_STATUSES
	errorMessage: string
	onInputChange: (value: string, isInputFilled: boolean) => void
	extraProps?: { [key: string]: string | boolean }
}

const regExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu

export const EmailInput: React.FC<Props> = props => {
	const [showTitle, setShowTitle] = useState(false)
	const location = useLocation()

	// Показываем title, если есть автофокус
	useEffect(()=> {
		if (props.extraProps?.autoFocus) {
			setShowTitle(true)
		}
	}, [])

	const checkIfEmailValid = (inputValue: string) => {
		return regExp.test(inputValue)
	}

	const handleInputChange = async (value: string) => {
		field.current.setCustomValidity("")

		props.onInputChange(value, true)

		value.length >= 0 ? setShowTitle(true) : setShowTitle(false)
	}

	const handleInvalidField = (msg = "Введите корректный email") => {
		field.current.setCustomValidity(msg)
	}

	const onBlur = async (value: string) => {
		let validState = false

		if (location.pathname.includes("/registration/")) {
			const state = await getEmailValidState(value)

			handleInvalidField(state.message)

			validState = state.valid
		} else {
			validState = regExp.test(value)
		}

		props.onInputChange(value, validState)

		value.length === 0 && setShowTitle(false)
	}

	const onFocus = () => {
		setShowTitle(true)
	}

	const field = useRef(null)

	return (
		<>
			<label
				htmlFor={props.inputId}
				className={classNames(styles.input, props.className, {
					[styles.inputFilled]: props.value.length,
				})}
			>
				<div
					className={classNames(styles.inputContainer, {
						[styles.inputContainer_error]: props.errorMessage,
					})}
				>
					<div className={styles.inputWithTitle}>
						{props.inputTitle && showTitle && (
							<p className={styles.inputTitle}>{props.inputTitle}</p>
						)}
						{
							<input
								ref={field}
								className={styles.inputField}
								type="email"
								id={props.inputId}
								placeholder={props.inputTitle || ""}
								value={props.value}
								onChange={event => {
									handleInputChange(event.target.value)
								}}
								onBlur={event => {
									onBlur(event.target.value)
								}}
								onFocus={() => {
									onFocus()
								}}
								onInvalid={() => handleInvalidField()}
								disabled={
									props.actionStatus === ACTION_STATUSES.SENDING ||
									props.actionStatus === ACTION_STATUSES.SUCCESS
								}
								{...props.extraProps}
							/>
						}
					</div>
					{!props.errorMessage && checkIfEmailValid(props.value) && (
						<div className={classNames(styles.icon, styles.icon_type_trailing)}>
							<ValidityOkIcon />
						</div>
					)}
				</div>

				{props.errorMessage && (
					<p
						className={classNames(
							styles.inputHelperText,
							styles.inputHelperText_type_error
						)}
					>
						{field.current.validationMessage}
					</p>
				)}
			</label>
		</>
	)
}
