import commonData from "../../pages-data/common/commonData"

export default {
	title: "Войдите в свой аккаунт",
	formFields: {
		email: {
			name: "email",
			label: "Email",
			type: "email",
			placeholder: "example@email.com",
		},
		password: {
			name: "password",
			label: "Пароль",
			type: "password",
			placeholder: "Введите пароль",
		},
	},
	submitButtonText: "Войти",
	recoveryPasswordLinkText: "Не удаётся войти?",
	errorData: {
		header: "Ошибка входа",
		description:
			"Вход прерван из-за проблем на сервере. Пожалуйста, попробуйте позже или обратитесь к нам для завершения процесса.",
		backButtonText: commonData.serverErrorScreen.buttonText,
		phone: commonData.phone,
		email: commonData.emailSupport,
		chatraGreetingsText:
			"Привет! Вижу, что произошла ошибка. Если исправили самостоятельно — отлично! Если нужна помощь — напишите мне, буду рад помочь!",
	},
}
