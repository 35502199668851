import * as React from "react"
import { SVGProps } from "react"

const EyeOpen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 7.6C15.1009 7.6 17.8664 9.304 19.2164 12C17.8664 14.696 15.1009 16.4 12 16.4C8.89909 16.4 6.13364 14.696 4.78364 12C6.13364 9.304 8.89909 7.6 12 7.6ZM12 6C7.90909 6 4.41545 8.488 3 12C4.41545 15.512 7.90909 18 12 18C16.0909 18 19.5845 15.512 21 12C19.5845 8.488 16.0909 6 12 6Z"
			fill="#7D828C"
		/>
		<path
			d="M12 9.99999C13.1291 9.99999 14.0455 10.896 14.0455 12C14.0455 13.104 13.1291 14 12 14C10.8709 14 9.95455 13.104 9.95455 12C9.95455 10.896 10.8709 9.99999 12 9.99999ZM12 8.39999C9.97091 8.39999 8.31818 10.016 8.31818 12C8.31818 13.984 9.97091 15.6 12 15.6C14.0291 15.6 15.6818 13.984 15.6818 12C15.6818 10.016 14.0291 8.39999 12 8.39999Z"
			fill="#7D828C"
		/>
	</svg>
)

export default EyeOpen
