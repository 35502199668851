import React, { useState } from "react"
import classNames from "classnames"

import AllSizedHelmet from "../components/AllSizedHelmet"
import HeaderAuthentication from "../components/_V2/HeaderAuthentication"
import FooterCompact from "../components/_V2/FooterCompact"

import LoginForm from "../components/_V2/LoginForm"
import RecoveryPasswordForm from "../components/_V2/RecoveryPasswordForm"
import RecoveryPasswordSuccessSection from "../components/_V2/RecoveryPasswordSuccessSection"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/registration.module.scss"

export default function PageLogin() {
	const [formData, setFormData] = useState({
		email: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		password: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
	})
	const [recoveryFormData, setRecoveryFormData] = useState({
		email: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
	})

	const [step, setStep] = useState(1)

	const defaultErrors = {
		email: "Введите корректный email",
		password: "Введите корректный пароль",
	}

	const handleLoginInputChange = (name, value, isFilled, errorMessage) => {
		if (!errorMessage) {
			errorMessage = defaultErrors[name]
		}

		setFormData({
			...formData,
			[name]: {
				errorMessage: !isFilled ? errorMessage : "",
				value,
				isFilled,
			},
		})
	}

	const handleRecoveryInputChange = (name, value, isFilled, errorMessage) => {
		if (!errorMessage) {
			errorMessage = defaultErrors[name]
		}

		setRecoveryFormData({
			...recoveryFormData,
			[name]: {
				errorMessage: !isFilled ? errorMessage : "",
				value,
				isFilled,
			},
		})
	}

	return (
		<main className={classNames(pageStyles.pageWrapper, styles.registration)}>
			<AllSizedHelmet>
				<title>
					Авторизация в Quick Resto – системе автоматизации кафе и ресторанов
				</title>

				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=5"
				/>
				<meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
				<meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />

				<meta
					name="description"
					content="Авторизуйтесь в личном кабинете Quick Resto и управляйте бизнесом удалённо: склад, номенклатура, финансы, аналитика, CRM и другие разделы."
				/>

				<meta property="og:url" content="https://quickresto.ru/login/" />
				<meta
					property="og:title"
					content="Авторизация в Quick Resto – системе автоматизации кафе и ресторанов"
				/>
				<meta
					property="og:description"
					content="Авторизуйтесь в личном кабинете Quick Resto и управляйте бизнесом удалённо: склад, номенклатура, финансы, аналитика, CRM и другие разделы."
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content="https://quickresto.ru//static/pres-45aab977d36977d92eb637008a13bda5.png"
				/>

				<link rel="canonical" href="https://quickresto.ru/login/" />
			</AllSizedHelmet>

			<div className={styles.registration__wrapper}>
				<HeaderAuthentication />

				<div className={styles.registration__content}>
					{step === 1 && (
						<LoginForm
							formData={formData}
							onChangeFormField={handleLoginInputChange}
							setStep={setStep}
						/>
					)}

					{step === 2 && (
						<RecoveryPasswordForm
							formData={recoveryFormData}
							onChangeFormField={handleRecoveryInputChange}
							setStep={setStep}
						/>
					)}

					{step === 3 && <RecoveryPasswordSuccessSection setStep={setStep} />}
				</div>

				<FooterCompact />
			</div>
		</main>
	)
}
