import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import { getData } from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function FooterCompact(props: Props) {
	const localizationContext = useLocalizationContext()

	const data = getData(localizationContext.locale)

	return (
		<footer className={classNames(styles.footer, props.className)}>
			<nav className={styles.footer__nav}>
				<span className={styles.footer__item}>
					&copy; {new Date().getFullYear()} Quick Resto
				</span>
				<a
					className={classNames(styles.footer__item, styles.footer__itemLink)}
					href={data.licenceAgreement.href}
					target="_blank"
					rel="noreferrer"
				>
					{data.licenceAgreement.text}
				</a>
				<a
					className={classNames(styles.footer__item, styles.footer__itemLink)}
					href={data.privacyPolicy.href}
					target="_blank"
					rel="noreferrer"
				>
					{data.privacyPolicy.text}
				</a>
			</nav>
		</footer>
	)
}
