import React, { useState, useEffect } from "react"
import classNames from "classnames"

import { EmailInput } from "../EmailInput"

import { recoverPassword } from "../../../apiRequests/login"

import styles from "./styles.module.scss"

import componentData from "../../../pages-data/login/loginPasswordRecovery"

export enum ACTION_STATUSES {
	SENDING = "SENDING",
	NOT_SEND = "NOT_SEND",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
}

interface Props {
	className?: string
	formData: {}
	onChangeFormField: Function
	setStep: Function
}

const initialRecoveryError = {
	error: "",
	show: false,
}

function RecoveryPasswordForm(props: Props) {
	const [actionStatus, setActionStatus] = useState<ACTION_STATUSES>(
		ACTION_STATUSES.NOT_SEND
	)
	const [isFormFilled, setIsFormFilled] = useState<boolean>(false)
	const [isFetching, setIsFetching] = useState(false)
	const [showRecoveryError, setShowRecoveryError] = useState(
		initialRecoveryError
	)

	useEffect(() => {
		if (Object.values(props.formData).every(field => field.isFilled)) {
			setIsFormFilled(true)
		} else {
			setIsFormFilled(false)
		}
	}, [props.formData])

	const handleSubmit = e => {
		e.preventDefault()

		setShowRecoveryError(initialRecoveryError)
		setIsFetching(true)

		recoverPassword(props.formData.email.value)
			.then(response => {
				if (response.isValid) {
					props.setStep(3)
				} else {
					setShowRecoveryError({
						error: response.invalidMessage,
						show: true,
					})
				}
				setIsFetching(false)
			})
			.catch(err => {
				console.error("Ошибка восстановления", err)

				setShowRecoveryError({
					error: componentData.errorData.description,
					show: true,
				})

				setIsFetching(false)
			})
	}

	return (
		<form
			className={classNames(styles.recoveryForm, props.className)}
			onSubmit={handleSubmit}
		>
			{!props.noTitle && (
				<h1 className={styles.recoveryForm__title}>Не удаётся войти?</h1>
			)}

			<p className={styles.recoveryForm__text}>
				Укажите эл. почту, которую использовали при регистрации, и мы отправим
				вам ссылку на восстановление пароля. Не помните адрес эл. почты?
				Позвоните по бесплатному номеру 
				<a href="tel:8 800 500-21-38" className={styles.recoveryForm__phone}>
					8 800 500-21-38
				</a>
				.
			</p>

			<EmailInput
				className={styles.recoveryForm__field}
				inputId="loginEmail"
				inputTitle="Электронная почта"
				value={props.formData.email.value}
				actionStatus={actionStatus}
				errorMessage={props.formData.email.errorMessage}
				onInputChange={(value, isInputValid) =>
					props.onChangeFormField("email", value, isInputValid)
				}
				extraProps={{
					required: true,
					autoFocus: true,
				}}
			/>

			{showRecoveryError.show && (
				<span className={styles.recoveryForm__error}>
					{showRecoveryError.error}
				</span>
			)}

			<button
				className={classNames(
					styles.recoveryForm__button,
					styles.recoveryForm__field
				)}
				type="submit"
				disabled={!isFormFilled || isFetching}
			>
				{componentData.submitButtonText}
			</button>

			<button
				type="button"
				className={styles.recoveryForm__recoveryBtn}
				onClick={() => props.setStep(1)}
				disabled={isFetching}
			>
				{componentData.backLinkText}
			</button>
		</form>
	)
}

export default RecoveryPasswordForm
