import React, { useState, useEffect } from "react"
import classNames from "classnames"

import { EmailInput } from "../EmailInput"
import { PasswordInput } from "../PasswordInput"

import { login } from "../../../apiRequests/login"

import styles from "./styles.module.scss"

import componentData from "../../../pages-data/login/mainForm"

export enum ACTION_STATUSES {
	SENDING = "SENDING",
	NOT_SEND = "NOT_SEND",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
}

interface Props {
	className?: string
	formData: {}
	onChangeFormField: Function
	setStep: Function
}

const initialLoginError = {
	error: "",
	show: false,
}

function LoginForm(props: Props) {
	const [actionStatus, setActionStatus] = useState<ACTION_STATUSES>(
		ACTION_STATUSES.NOT_SEND
	)
	const [isFormFilled, setIsFormFilled] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [showLoginError, setShowLoginError] = useState(initialLoginError)

	useEffect(() => {
		if (Object.values(props.formData).every(field => field.isFilled)) {
			setIsFormFilled(true)
		} else {
			setIsFormFilled(false)
			setShowLoginError(initialLoginError)
		}
	}, [props.formData])

	const handleSubmit = e => {
		e.preventDefault()

		setShowLoginError(initialLoginError)
		setIsFetching(true)

		login({
			email: props.formData.email.value,
			password: props.formData.password.value,
		})
			.then(response => {
				if (response.isValid) {
					window.location.href = "/personal/"
				} else {
					setShowLoginError({
						error: response.invalidMessage,
						show: true,
					})
					setIsFetching(false)
				}
			})
			.catch(err => {
				console.error("Ошибка входа", err)

				setShowLoginError({
					error: componentData.errorData.description,
					show: true,
				})

				setIsFetching(false)
			})
	}

	return (
		<form
			className={classNames(styles.loginForm, props.className)}
			onSubmit={handleSubmit}
		>
			<h1 className={styles.loginForm__title}>Войдите в аккаунт</h1>

			<EmailInput
				className={styles.loginForm__field}
				inputId="loginEmail"
				inputTitle="Электронная почта"
				value={props.formData.email.value}
				actionStatus={actionStatus}
				errorMessage={props.formData.email.errorMessage}
				onInputChange={(value, isInputValid) =>
					props.onChangeFormField("email", value, isInputValid)
				}
				extraProps={{
					required: true,
					autoFocus: true,
				}}
			/>

			<PasswordInput
				className={styles.loginForm__field}
				inputId="loginPassword"
				inputTitle="Пароль"
				value={props.formData.password.value}
				actionStatus={actionStatus}
				errorMessage={props.formData.password.errorMessage}
				onInputChange={(value, isInputFilled) =>
					props.onChangeFormField("password", value, isInputFilled)
				}
				extraProps={{
					required: true,
				}}
			/>

			{showLoginError.show && (
				<span className={styles.loginForm__error}>{showLoginError.error}</span>
			)}

			<button
				className={classNames(
					styles.loginForm__button,
					styles.loginForm__field
				)}
				type="submit"
				disabled={!isFormFilled || isFetching}
			>
				{componentData.submitButtonText}
			</button>

			<button
				type="button"
				className={styles.loginForm__recoveryBtn}
				onClick={() => props.setStep(2)}
				disabled={isFetching}
			>
				{componentData.recoveryPasswordLinkText}
			</button>
		</form>
	)
}

export default LoginForm
