import React from "react"
import classNames from "classnames"

import SuccessRecovery from "./assets/SuccessRecovery"

import styles from "./styles.module.scss"

import componentData from "../../../pages-data/login/loginPasswordRecoverySuccess"

interface Props {
	className?: string
	setStep: Function
}

function RecoveryPasswordSuccessSection(props: Props) {
	return (
		<section className={classNames(styles.recoverySuccess, props.className)}>
			<SuccessRecovery />

			<h1 className={styles.recoverySuccess__title}>{componentData.title}</h1>

			<p className={styles.recoverySuccess__text}>
				{componentData.description}
			</p>

			<button
				type="button"
				className={styles.recoverySuccess__button}
				onClick={() => props.setStep(1)}
			>
				{componentData.backButtonText}
			</button>
		</section>
	)
}

export default RecoveryPasswordSuccessSection
