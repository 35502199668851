import React from "react"
import { useLocation } from "@reach/router"

import AdaptiveLink from "../AdaptiveLink"

import styles from "./styles.module.scss"

import Logo from "../Header/assets/Logo"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import classNames from "classnames"

interface Props {
	className?: string
}

export default function HeaderAuthentication(props: Props) {
	const location = useLocation()

	return (
		<header className={classNames(styles.header, props.className)}>
			<AdaptiveLink className={styles.header__logo} href="/" useGatsbyLink>
				<Logo />
			</AdaptiveLink>

			<div className={styles.header__auth}>

				{/* Панель для страницы логина */}
				{location.pathname.includes('log') && (
					<>
						<AdaptiveLink
							className={styles.header__authBtn}
							href={pagesLinks.registration.href}
							useGatsbyLink
						>
							Регистрация
						</AdaptiveLink>
					</>
				)}

				{/* Панель для страницы регистрации */}
				{location.pathname.includes('reg') && (
					<>
						<span className={styles.header__loginLabel}>Уже есть аккаунт?</span>

						<AdaptiveLink
							className={styles.header__authBtn}
							href={pagesLinks.login.href}
							useGatsbyLink
						>
							Войти
						</AdaptiveLink>
					</>
				)}
				
			</div>
		</header>
	)
}
