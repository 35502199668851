import { NetError } from "./utils"

/**
 * Авторизация
 * @param {Object} credentials - Сочетание почты и пароля
 * @param {string} credentials.email
 * @param {string} credentials.password
 * @returns {Promise<Object>} result
 * @returns {boolean} result.isValid - Валиден ли телефон
 * @returns {string} result.invalidMessage - Причина невалидности
 */
export function login(credentials) {
	return (
		fetch("/api_controller/?module=user&method=signIn", {
			method: "POST",
			body: JSON.stringify({
				...credentials,
				remember: false,
			}),
			headers: {
				Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
			},
		})
			.then(rawResponse => rawResponse.json())
			.then(response => {
				if (response.status === 200) {
					return {
						isValid: true,
					}
				} else if (response.status === 400) {
					return {
						isValid: false,
						invalidMessage: response.statusText,
					}
				} else {
					// Если код ответа не 200 или 400 кинтуть ошибку
					throw "Http status error"
				}
			})
			// Обработка ошибки либо из предидущего обработчика либо возникшая при парсинге `json`
			.catch(error => {
				throw new NetError(error.message)
			})
	)
}

/**
 * Запрос на отправку письма с ссылкой на восстановление
 * @param {string} email
 * @returns {Promise<Object>} result
 * @returns {boolean} result.isValid - Валиден ли телефон
 * @returns {string} result.invalidMessage - Причина невалидности
 */
export function recoverPassword(email) {
	return (
		fetch("/api_controller/?module=user&method=passwordRecover", {
			method: "POST",
			body: JSON.stringify({
				email,
			}),
			headers: {
				Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
			},
		})
			.then(rawResponse => rawResponse.json())
			.then(response => {
				if (response.status === 200) {
					return {
						isValid: true,
					}
				} else if (response.status === 400) {
					return {
						isValid: false,
						invalidMessage: response.statusText,
					}
				} else {
					// Если код ответа не 200 или 400 кинтуть ошибку
					throw "Http status error"
				}
			})
			// Обработка ошибки либо из предидущего обработчика либо возникшая при парсинге `json`
			.catch(error => {
				throw new NetError(error.message)
			})
	)
}

/**
 * @param {Object} payload - Сочетание почты и пароля
 * @param {string} payload.login
 * @param {string} payload.token
 * @param {string} payload.password
 * @param {string} payload.passwordConfirm
 * @returns {Promise<Object>} result
 * @returns {boolean} result.isValid - Валиден ли телефон
 * @returns {string} result.invalidMessage - Причина невалидности
 * @param {string} payload
 */
export function setNewPassword(payload) {
	return (
		fetch("/api_controller/?module=user&method=setPassword", {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
				Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
			},
		})
			.then(rawResponse => rawResponse.json())
			.then(response => {
				if (response.status === 200) {
					return {
						isValid: true,
					}
				} else if (response.status === 400) {
					return {
						isValid: false,
						invalidMessage: response.statusText,
					}
				} else {
					// Если код ответа не 200 или 400 кинтуть ошибку
					throw "Http status error"
				}
			})
			// Обработка ошибки либо из предидущего обработчика либо возникшая при парсинге `json`
			.catch(error => {
				throw new NetError(error.message)
			})
	)
}
